export const menuItems = [
  {
    path: "/",
    text: "home",
  },

  {
    path: "/lionsinfo",
    text: "About Lions",
  },
  {
    path: "/stories",
    text: "Lions Stories",
  },
  {
    path: "/contact",
    text: "Get In Touch",
  },
]
