import React from "react"
import AnimMain from "./src/components/AnimMain/AnimMain"
import Footer from "./src/components/Footer/Footer"
import Layout from "./src/components/Layout"
import { MenuProvider } from "./src/components/MenuContext"
import NavModule from "./src/components/NavModule/NavModule"
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./src/styles/globals.css"


export function wrapPageElement({ element, props }) {
  return (
    <>
    <ToastContainer
      autoClose = {false}
      hideProgressBar = {true}
      newestOnTop = {true}      
    />
    <Layout {...props}>
      <NavModule />
      <AnimMain>
        {element}
        <Footer />
      </AnimMain>      
    </Layout>
    </>
  )
}

export function wrapRootElement({ element }) {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_V3_SITE_KEY}>
      <MenuProvider>{element}</MenuProvider>      
    </GoogleReCaptchaProvider>
  )
}
