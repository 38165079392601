import React from "react"
import { Link } from "gatsby"
import { menuItems} from "../../constants/links"
import { FooterStyles } from "./FooterStyles"
import { useStaticQuery, graphql } from "gatsby"
import {
  FaFacebookSquare as Facebook,
  FaTwitterSquare as Twitter,
  FaInstagram as Instagram,  
  FaYoutubeSquare as YouTube,
  FaPinterestSquare as Pinterest,
  FaSnapchatSquare as Snapchat,
  FaTiktok as Tiktok
} from "react-icons/fa"

const socialMediaItems = graphql`
  query {
    items: allContentfulSiteConfiguration {
      edges {
        node {
          footerSocialItems {
            icon
            url
          }
        }
      }
    }
  }
`


const Footer = () => {
  const socialItemsQuery = useStaticQuery(socialMediaItems);
  const socialItems = socialItemsQuery.items.edges[0].node.footerSocialItems;
  return (
    <FooterStyles>
      <div className="container">
        <div className="footer__menu">
          <ul>
            {menuItems.map((item, index) => {
              return (
                <li key={index}>
                  <Link to={item.path} activeClassName="menu__item--active">
                    {item.text}
                    <span>.</span>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="social__menu">
          <ul>
            {socialItems.map((item, index) => {
             
              return (
                <li key={index}>
                  <a
                    href={item.url}
                    target="_blank"
                    rel="nofollow noreferrer noopener"                    
                  >                   
                  { (item.icon==="Facebook") && <Facebook /> }
                  { (item.icon==="Twitter") && <Twitter /> }
                  { (item.icon==="Instagram") && <Instagram /> }
                  { (item.icon==="YouTube") && <YouTube /> }
                  { (item.icon==="Pinterest") && <Pinterest /> }
                  { (item.icon==="Snapchat") && <Snapchat /> }
                  { (item.icon==="Tiktok") && <Tiktok /> }
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="copyright">
          <p>
            © 2022 Lions Australia | District 201N2 of Lions Clubs International Inc | ABN: 45 908 406 652 | Site built by Lions N2 Division Public Relations Team            
            <span>.</span>  | <a href="/lionsinfo/privacy">Privacy Policy</a>
          </p>
        </div>
      </div>
    </FooterStyles>
  )
}

export default Footer
