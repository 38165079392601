exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-templates-lionsinfo-list-template-js": () => import("./../../../src/templates/lionsinfo-list-template.js" /* webpackChunkName: "component---src-templates-lionsinfo-list-template-js" */),
  "component---src-templates-lionsinfo-template-js": () => import("./../../../src/templates/lionsinfo-template.js" /* webpackChunkName: "component---src-templates-lionsinfo-template-js" */),
  "component---src-templates-lionsstory-template-js": () => import("./../../../src/templates/lionsstory-template.js" /* webpackChunkName: "component---src-templates-lionsstory-template-js" */),
  "component---src-templates-pillarstories-template-js": () => import("./../../../src/templates/pillarstories-template.js" /* webpackChunkName: "component---src-templates-pillarstories-template-js" */)
}

